export const englishContent = {
    gallery: "Gallery",
    services: "Services",
    reviews: "Reviews",
    contact: "Contact",
}

export const spanishContent = {
    gallery: "Galería",
    services: "Servicios",
    reviews: "Testimonios",
    contact: "Contacto",
}
